import React from 'react';
import { 
  Container,
  ActionButton
} from "./styled"
import { Link } from 'react-scroll'
import SVG from 'react-inlinesvg';

const Header = () => (
  <Container> 
    <a href="https://www.eyecarehealth.com.br/">
      <SVG src={'/img/marca.svg'} className="svg color"/>
    </a>
    <Link to="form" spy={true} smooth={true} hashSpy={true} duration={500}>
      <ActionButton>Agendar demonstração</ActionButton>
    </Link>
  </Container>
)

export default Header