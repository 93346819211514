import styled from 'styled-components';
import media from 'styled-media-query';

const Container = styled.footer`
  background-color: transparent;
  padding: 0;
  overflow: hidden;

  div:first-child {
    color: #FFFFFF;
    background: #0C1D59;
    padding: 48px;
    display: grid;
    grid-template-areas: "logo social"
                         "mail tel";
    grid-template-columns: auto max-content;
    grid-template-rows: 1fr 1fr;
    grid-gap: 24px;
    align-items: center;

    ${media.lessThan('medium')` 
      height: 264px; 
      padding: 48px 24px;
      grid: none;
      grid-template-areas: "logo"
                           "social"
                           "mail"
                           "tel"
    `}

    .social-icons {
      grid-area: social;
      display: flex;
      justify-content: flex-end;
      gap: 24px;

      ${media.lessThan('medium')` 
        justify-content: flex-start;
      `}
    }

    img {
      grid-area: logo;
      ${media.lessThan('medium')`  
        margin: 0;
      `}
    }

    p {
      margin: 0;
      text-align: right;
      ${media.lessThan('medium')`  
        text-align: left;
      `}

      &:nth-child(1) {
        grid-area: tel;
      }
      &:nth-child(2) {
        grid-area: mail;    
      }
    }
  }

  div:last-child {
    background: #E9F2FB;
    padding: 0 48px;
    height: 90px;
    display: grid;
    grid-template-columns: auto max-content max-content;
    grid-gap: 24px;
    align-items: center;
    color: #0C1D59;

    ${media.lessThan('medium')`  
      grid: none;
      grid-gap: 0px;
      display: flex;
      flex-direction: column-reverse;
      align-items: start;
      padding: 32px;
      height: auto;
    `}

    a {
      color: #0C1D59;
      text-decoration: none;
      ${media.lessThan('medium')`  
        margin-bottom: 8px;
        text-align: left;
        font-size: 16px;
        line-height: 160%;
        color: #273FD7;
      `}
    }
  }
`;

export { 
  Container
};