import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import SVG from 'react-inlinesvg';
import { 
  FeaturesHeader,
  MiniFeaturesList,
  MiniFeature,
  FeatureBox,
  ImageBox,
  CheckList
} from "./styled";

const BiFeaturesSection = () => (
  <section>
    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <FeaturesHeader>
        <h1>A melhor ferramenta para atender e acompanhar todos os seus pacientes</h1>
        <MiniFeaturesList>
          <MiniFeature>
            <SVG src={'/bi-glauco/icon-prontuario.svg'}/>
            <h3>Prontuário eletrônico</h3>
            <p>Ganhe tempo com um prontuário exclusivo para oftalmologia.</p>
          </MiniFeature>
          <MiniFeature>
            <SVG src={'/bi-glauco/icon-documentos.svg'}/>
            <h3>Emissão de documentos</h3>
            <p>Preencha rapidamente prescrições, atestados e outros.</p>
          </MiniFeature>
          <MiniFeature>
            <SVG src={'/bi-glauco/icon-agenda.svg'}/>
            <h3>Agenda</h3>
            <p>Agendamento fácil, eficiente e seguro para a sua clínica.</p>
          </MiniFeature>
          <MiniFeature>
            <SVG src={'/bi-glauco/icon-chat.svg'}/>
            <h3>Chat com o paciente</h3>
            <p>Uma forma de comunicação dinâmica e segura entre médico e paciente, respeitando a LGPD.</p>
          </MiniFeature>
        </MiniFeaturesList>
      </FeaturesHeader>
    </ScrollAnimation>

    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <FeatureBox>
        <div className="content">
          <h2>Prontuário eletrônico feito por você</h2>
          <p>Ganhe mais tempo com o seu paciente durante a consulta utilizando um prontuário exclusivo para oftalmologia e totalmente personalizável.</p>
          <CheckList>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Preenchimento rápido e intuitivo</p>
            </li>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Acesso rápido a pré-consulta</p>
            </li>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Fácil visualização de exames complementares</p>
            </li>
          </CheckList>
        </div>
        <ImageBox>
          <img src={'/bi-glauco/prontuario-feature.png'} />
        </ImageBox>
      </FeatureBox>
    </ScrollAnimation>

    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <FeatureBox>
        <div className="content">
          <h2>Emissão de documentos e solicitações</h2>
          <p>Solicite exames e encaminhamentos, prescreva medicamentos e óculos e ofereça atestados e declarações com poucos cliques. </p>
          <CheckList>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Assinatura digital segura</p>
            </li>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Prescrição online</p>
            </li>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Personalize os documentos com o seu logotipo</p>
            </li>
          </CheckList>
          <span>*Apesar da nossa política paperless, se você preferir, pode imprimir seus documentos.</span>
        </div>
        <ImageBox>
          <img className="documents" src={'/bi-glauco/documentos-feature.png'} />
        </ImageBox>
      </FeatureBox>
    </ScrollAnimation>

    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <FeatureBox>
        <div className="content">
          <h2>Agenda</h2>
          <p>Ganhe agilidade com um agendamento mais eficiente, seguro e customizável.</p>
          <CheckList>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Preenchimento eficiente de horários</p>
            </li>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Ícones detalhando suas atividades</p>
            </li>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Envie lembretes para seus pacientes</p>
            </li>
            <li>
              <SVG src={'/bi-glauco/icon-check.svg'}/>
              <p>Acesse de qualquer lugar</p>
            </li>
          </CheckList>
        </div>
        <ImageBox className="calendar">
          <img src={'/bi-glauco/agenda-feature-1.png'} />
          <img src={'/bi-glauco/agenda-feature-2.png'} />
        </ImageBox>
      </FeatureBox>
    </ScrollAnimation>

    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <FeatureBox>
        <div className="content center">
          <h2>Chat com paciente</h2>
          <p>Um canal de comunicação seguro entre você e seus pacientes. Cuidado mais personalizado, rápido e integrado com outras ferramentas (Glauco), aumentando o engajamento de seus pacientes e agregando valor para seu consultório.</p>
        </div>
        <ImageBox>
          <img src={'/bi-glauco/chat-feature.png'} />
        </ImageBox>
      </FeatureBox>
    </ScrollAnimation>
  </section>
)

export default BiFeaturesSection