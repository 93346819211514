import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import SVG from 'react-inlinesvg';
import { Link } from 'react-scroll'
import {
  Section,
  HeaderContent,
  AccessFeatures,
  ActionButton
} from './styled'

const BiAccessSection = () => (
  <Section>
    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <HeaderContent>
        <div>
          <h1>Acessos personalizados</h1>
          <p>O time da sua instituição terá acesso às funcionalidades necessárias para efetuar seus trabalhos do dia-a-dia. Seja médico(a), secretário(a), assistente, gestor(a) ou técnico(a), cada um tem seu acesso e fluxo individual.</p>
        </div>
        <img src={'/bi-glauco/access-dasboard.png'} />
      </HeaderContent>
    </ScrollAnimation>

    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <AccessFeatures>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-biometria.svg'} />
          <h3>Assinatura digital</h3>
          <p>Tenha todos os dados de seus pacientes em nuvem, sem a necessidade de impressão de documentos. Tudo isso com validade jurídica e segurança de dados.</p>
        </div>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-cadeado.svg'} />
          <h3>Segurança dos dados</h3>
          <p>Em consonância com a Lei Geral de Proteção de Dados (LGPD), apenas os profissionais de saúde liberados têm acesso aos dados sensíveis de pacientes.</p>
        </div>
        <Link to="form" spy={true} smooth={true} hashSpy={true} duration={500}>
          <ActionButton>Agendar demonstração</ActionButton>
        </Link>
      </AccessFeatures>
    </ScrollAnimation>
  </Section>
)

export default BiAccessSection