import React from 'react';
import HeadMetaData from '../HeadMetaData';
import { ToastContainer } from 'react-toastify';
import Header from "../Header"
import Footer from '../Footer';
import IntroSection from './IntroSection'
import BiFeaturesSection from './BiFeaturesSection'
import BiAccessSection from './BiAccessSection';
import GlaucoFeaturesSection from './GlaucoFeaturesSection';
import ScrollAnimation from 'react-animate-on-scroll';
import Form from "./Form";

const BiGlauco = () => (
  <>
    <HeadMetaData
      siteName={"Eyecarehealth"}
      title={"Software medico – Eyecare Bi"}
      url={"https://eyecarebieglauco.eyecarehealth.com.br/"}
      description={"Software médico online totalmente pensado para a oftalmologia. Prontuário médico eletrônico com ampla base de protocolos de fácil acesso e telemedicina integrada. Gestão de pacientes, gestão financeira e muito mais para seu consultório, clínica ou hospital."} />
    <ToastContainer />
    <Header />
    <IntroSection />
    <BiFeaturesSection />
    <BiAccessSection/>
    <GlaucoFeaturesSection/>
    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <Form />
    </ScrollAnimation>
    <Footer />
  </>
)

export default BiGlauco