import styled from 'styled-components';
import media from 'styled-media-query';

const FeaturesHeader = styled.div`
  margin: 104px 7.29vw;
  ${media.lessThan('medium')`  
    margin: 48px 32px 80px 32px;
  `}

  h1 {
    width: 64%;
    font-weight: 500;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.04em;
    color: #1A1B23;
    margin-bottom: 48px;

    ${media.lessThan('medium')`  
      width: 100%;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 32px;
    `}
  }
`;

const MiniFeaturesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  ${media.lessThan('medium')`  
    flex-direction: column;
    gap: 32px;
  `}
`;

const MiniFeature = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 224px;
  width: 15.56vw;
  ${media.lessThan('medium')`  
    width: 100%;
  `}

  h3 {
    font-size: 16px;
    line-height: 160%;
    color: #1A1B23;
    ${media.lessThan('medium')`  
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      letter-spacing: -0.04em;
      color: #1A1B23;
    `}
  }

  p { 
    font-size: 14px;
    line-height: 160%;
    color: #62636A;
    ${media.lessThan('medium')`  
      font-size: 16px;
    `}
  }
`;

const FeatureBox = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  grid-gap: 3.5vw;
  background: #F5F5F6;
  border-radius: 32px;
  margin: 0 7.22vw 40px 7.22vw;
  min-height: 541px;
  overflow: hidden;
  ${media.lessThan('medium')`  
    grid: none;
    min-height: initial;
    margin: 0 8px 16px 8px;
  `}

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 5.56vw;
    ${media.lessThan('medium')`
      margin: 0 24px;
    `}

    &.center {
      justify-content: center;
      h2 {
        margin-top: 16px;
        ${media.lessThan('medium')`
          margin-top: 40px;
        `}
      }
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.04em;
      color: #1A1B23;
      margin-top: 106px;
      margin-bottom: 16px;
      max-width: 264px;
      ${media.lessThan('medium')`
        margin-top: 40px;
      `}
    }

    p {
      font-size: 18px;
      line-height: 150%;
      letter-spacing: -0.04em;
      color: #62636A;
      ${media.lessThan('medium')`
        font-size: 16px;
        line-height: 160%;
      `}
    }

    span {
      margin-top: 32px;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 160%;
      color: #62636A;
      max-width: 313px;
    }
  }
`;

const ImageBox = styled.div`
  width: 29.70vw;
  justify-self: flex-end;
  background: #305BF2;
  height: 100%;
  ${media.lessThan('medium')`
    width: 100%;
    background: #305BF2;
    margin-top: 88px;
    padding: 0 24px;
    height: 200px;
  `}

  &.calendar {
    ${media.lessThan('medium')`
      position: relative;
    `}

    img:nth-child(1) {
      max-width: 640px;
      ${media.lessThan('medium')`
        max-width: initial;
        margin-top: -32px;
      `}
    }

    img:nth-child(2) {
      max-width: 300px;
      width: 21.04vw;
      ${media.lessThan('medium')`
        position: absolute;
        top: 0;
        right: 24px;
        width: 40%;
        max-width: initial;
      `}
    }
  }

  img {
    width: 41.94vw;
    max-width: 580px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2.78vw;
    box-shadow: 0px 38.0472px 71.3386px -28.5354px rgba(0, 0, 0, 0.25);
    ${media.lessThan('medium')`
      width: 100%;
      margin-top: -64px;
      position: relative;
      max-width: initial;
      top: initial;
      transform: initial;
      right: initial;
    `}

    &.documents {
      margin-top: 42px;
      border-radius: 0px 0px 16px 16px;

      ${media.lessThan('large')`  
        margin-top: 0;
      `}
      
      ${media.lessThan('medium')`
        margin-top: -64px;
      `}
    }
  }
`;

const CheckList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
  li {
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 8px;
    align-items: center;
  }
`;

export { 
  FeaturesHeader,
  MiniFeaturesList,
  MiniFeature,
  FeatureBox,
  ImageBox,
  CheckList
};