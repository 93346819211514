import React from 'react';
import {
  Section, 
  Content,
  ProductName,
  Title,
  Description,
  ActionButton,  
  Ornaments,
  DashboardBi,
  DashboardBiMobile
} from './styled';
import { Link } from 'react-scroll'
import ScrollAnimation from 'react-animate-on-scroll'

const IntroSection = () => (
  <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
    <Section>
      <Content>
        <ProductName>Eyecare BI + Glauco</ProductName>
        <Title>Solução completa para sua clínica, focada no seu crescimento.</Title>
        <Description>Com um sistema especializado em oftalmologia, você será capaz de acompanhar todos os seus pacientes de perto e gerenciar as principais áreas da sua instituição de saúde, esteja onde você estiver e a qualquer momento do dia.</Description>
        <Link to="form" spy={true} smooth={true} hashSpy={true} duration={500}>
          <ActionButton>Agendar demonstração</ActionButton>
        </Link>
      </Content>
      <Ornaments>
        <DashboardBi src={'/bi-glauco/intro-dashboard-bi.png'}/>
        <DashboardBiMobile src={'/bi-glauco/intro-dashboard-bi-mobile.png'}/>
      </Ornaments>
    </Section>
  </ScrollAnimation>
)


export default IntroSection