import * as React from "react"
import { Helmet } from "react-helmet"; 
import PropTypes from 'prop-types';

const HeadMetaData = ({title, url, description, siteName, children}) => {
  const thumbnail = 'https://eyecarebieglauco.eyecarehealth.com.br/img/thumbnail.jpg';
  const injectGA = () => {
    if (typeof window == 'undefined') {
      return;
    }
    var dataLayer = window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-364779484');
  }
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name='description' content={description}/>
      
      { /* Social: Twitter */ }
      <meta name='twitter:card' content='summary_large_image'/>
      <meta name='twitter:title' content={title}/>
      <meta name='twitter:description' content={description}/>
      <meta name='twitter:image:src' content={thumbnail}/>
        
      { /* Social: Facebook / Open Graph */ }
      <meta property='og:url' content={url}/>
      <meta property='og:type' content='website'/>
      <meta property='og:title' content={title}/>
      <meta property='og:image' content={thumbnail}/>
      <meta property='og:description' content={description}/>
      <meta property='og:site_name' content={siteName}/>
      <meta name="theme-color" content="#0B30B2"/>

      {/* Global site tag (gtag.js) - Google Ads: 364779484 */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-364779484"></script>
      <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/043ddeca-3c0a-4c3a-89ab-10ffd3c04f6e-loader.js" ></script>
      <script>{injectGA()}</script>

      {children}
    </Helmet>
  )
}

HeadMetaData.propTypes = {
  title: PropTypes.string, 
  url: PropTypes.string, 
  description: PropTypes.string, 
  siteName: PropTypes.string, 
  children: PropTypes.element
};

export default HeadMetaData