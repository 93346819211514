import React from 'react';
import SVG from 'react-inlinesvg/esm';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-scroll';
import {
  Section,
  HeaderContent,
  FeaturesList,
  ActionButton
} from './styled';

const GlaucoFeaturesSection = () => (
  <Section>
    <span id="glauco" className="glauco-anchor"></span>
    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <HeaderContent>
        <SVG src={'/bi-glauco/glauco-logo.svg'}/>
        <h1>Você e seu paciente conectados contra o glaucoma e à cegueira irreversível.</h1>
        <p>Com o Glauco, você terá maior transparência sobre o tratamento e acesso a dados sobre a saúde ocular de seus pacientes com glaucoma. Enquanto isso, seu paciente terá total controle sobre a própria aderência ao tratamento e um assistente durante esta jornada.</p>
        <div className="image-box">
          <img src={'/bi-glauco/phone-glauco.png'}/>
          <img src={'/bi-glauco/phone-glauco-mobile.png'}/>
        </div>
      </HeaderContent>
    </ScrollAnimation>
    <ScrollAnimation animateIn="animate__fadeIn" delay={1} animateOnce>
      <FeaturesList>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-engajamento.svg'} />
          <h3>Engajamento</h3>
          <p>Incentive seu paciente a participar ativamente do próprio tratamento do Glaucoma. Ele terá na palma da mão a visualização sobre a aderência, informações sobre a doença, dados de saúde, entre outros. Estudos comprovam que o uso correto de colírios é um dos tratamentos que mais impactam positivamente à saúde ocular ao longo dos anos em pacientes com glaucoma.</p>
        </div>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-tratamento.svg'} />
          <h3>Tratamento efetivo</h3>
          <p>Ajudamos seu paciente durante toda a jornada pelo tratamento. Com uma experiência exclusiva para o glaucoma, o paciente, ou o cuidador, terá controle sobre o uso dos colírios (horários, tipos de colírios cadastrados, histórico de colírios utilizados) com uma linguagem amigável e embasada na literatura. Tudo isso registrado no aplicativo.</p>
        </div>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-relacao.svg'} />
          <h3>Relação médico-paciente</h3>
          <p>Acesso a dados precisos e o contato direto entre médico e paciente. Mais uma vez a tecnologia aproximando esse binômio. Com o acompanhamento diário dos seus pacientes, você será capaz de entender melhor os hábitos, comportamento e a evolução do tratamento.</p>
        </div>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-saude.svg'} />
          <h3>Saúde ocular na palma da mão</h3>
          <p>Todo o histórico do paciente em um único local: no Glauco. Aqui ele terá acesso à informações como: resumos de consultas, exames realizados, prescrições e cirurgias ao longo da vida. Desse modo, construímos um histórico de saúde para o paciente e aumentamos o sucesso e a eficiência ao tratamento.</p>
        </div>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-tele.svg'} />
          <h3>Teleoftalmologia</h3>
          <p>Dentro do propósito da Eyecare de democratizar o acesso à saúde ocular, a Teleoftalmolgia elimina barreiras entre médicos e pacientes e permite um tratamento personalizado de qualquer lugar, suplantando a visita ao consultório para questões triviais. Isso tudo utilizando as ferramentas disponibilizadas na Eyecare.*</p>
          <span>*em implementação</span>
        </div>
        <div className="feature-box">
          <SVG src={'/bi-glauco/icon-integracao.svg'} />
          <h3>Integração entre Glauco e EyecareBI</h3>
          <p>Todos os dados de acompanhamento do paciente serão disponibilizados em seu perfil médico no EyecareBI. Assim, você poderá orientá-lo e tomar decisões baseadas em dados fidedignos e atualizados sobre a jornada diária contra o glaucoma.</p>
        </div>
      </FeaturesList>
      <Link to="form" spy={true} smooth={true} hashSpy={true} duration={500}>
        <ActionButton>Agendar demonstração</ActionButton>
      </Link>
    </ScrollAnimation>
  </Section>
)

export default GlaucoFeaturesSection