import React, { useState } from 'react';
import {
  Container,
  LoaderBox,
  TitleBox,
  FormContent,
  FormGroup,
  Input,
  Select,
  CheckBox,
  Button
} from './styled';
import { Element } from 'react-scroll'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { navigate } from "@reach/router"
import validatePhoneInput from "../../../utils/validatePhoneInput"

const profissoes = [
  "Médico(a)",
  "Outro profissional da saúde",
  "Gestor(a)",
  "Secretário(a)",
  "Responsável técnico de TI",
  "Consultor",
  "Paciente",
  "Acadêmico",
]

const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, formState: { errors }, handleSubmit } = useForm()
  const onSubmit = (data) => {
    setIsLoading(true)
    axios({
      method: "POST",
      url: "https://eyecarebieglauco.eyecarehealth.com.br/api/send-form",
      data
    }).then(() => {
      return navigate('/agradecimento/');
    }, () => {
      toast.error("Erro ao enviar o formulario, por favor tente novamente.")
    }).finally(() => setIsLoading(false))
  }

  return (<>
    { isLoading && 
      <LoaderBox>
        <ReactLoading type="bubbles" color="#273FD7" height={'300px'} width={'300px'}/>
      </LoaderBox>
    }
    <Element name="form">
      <Container>
        <TitleBox>
          <h1>Fale Conosco</h1>
          <p>Converse com um de nossos consultores sobre o Benefício de visão.</p>
        </TitleBox>
        <FormContent onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <label>Nome</label>
            <Input 
              type="text"
              {...register("nome", { 
                required: true, 
                minLength: 2,
                pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
              })} 
              className={errors.nome && 'invalid'}
              placeholder="Nome completo" 
              required/>
            {errors.nome && <span className="input-error">Nome inválido</span>}
          </FormGroup>
          <FormGroup>
            <label>Email</label>
            <Input 
              type="email" 
              {...register("email", { 
                required: true,  
                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ 
              })} 
              className={errors.email && 'invalid'}
              placeholder="Email" 
              required/>
            {errors.email && <span className="input-error">Email inválido</span>}
          </FormGroup>
          <FormGroup>
            <label>Telefone</label>
            <Input 
              type="tel" 
              {...register("telefone", {
                required: true,
              })}
              className={errors.telefone && 'invalid'}
              onKeyPress={validatePhoneInput}
              placeholder="Telefone" 
              minLength="14"
              required/>
            {errors.telefone && <span className="input-error">Telefone inválido</span>}
          </FormGroup>  
          <FormGroup>
            <label>Profissão</label>
            <Select 
              {...register("profissao", { required: true })} 
              className={errors.profissao && 'invalid'}
              defaultValue={""} 
              required>
              <option value="" disabled>Selecionar</option>
              {profissoes.map((item, index) => <option key={index} value={item}>{item}</option>)}
            </Select>
          </FormGroup>
          <FormGroup>
            <label>Nome da sua instituição</label>
            <Input 
              type="text"
              {...register("instituicao", {
                required: true,
                minLength: 2
              })}
              className={errors.instituicao && 'invalid'}
              placeholder="Nome da sua instituição"
              required/>
            {errors.instituicao && <span className="input-error">Instituição inválida</span>}
          </FormGroup>
          <FormGroup>
            <label>Quantidade de médicos</label>
            <Input 
              type="number" 
              {...register("qtdMedicos", { 
                required: true, 
                min: 1 
              })} 
              placeholder="Quantidade de médicos" 
              min="1" 
              required/>
          </FormGroup>
          <CheckBox>
            <input type="checkbox" {...register("aceitoReceberEmail")} />
            <p>Aceito receber e-mails sobre as <span>novidades Eyecare.</span></p>
          </CheckBox>
          <Button type="submit">Enviar</Button>
        </FormContent>
      </Container>
    </Element>
  </>)
}


export default Form

