import styled from 'styled-components';
import media from 'styled-media-query';

const Section = styled.section`
  min-height: 800px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  ${media.lessThan('medium')`
    grid: none;         
  `}
`;

const Content = styled.div`
  margin: 0 0 91px 7.22vw;
  padding-top: 122px;
  z-index: 1;
  ${media.lessThan('medium')`  
    margin: 0 16px 48px 16px;     
  `}
`;

const ProductName = styled.p`
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #62636A;
  margin-bottom: 24px;
  ${media.lessThan('medium')`  
    margin-bottom: 16px;
  `}
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 56px;
  line-height: 110%;
  letter-spacing: -0.04em;
  color: #1A1B23;
  margin-bottom: 16px;
  ${media.lessThan('medium')`  
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
  `}
`;

const Description = styled.p`
  width: 85%;
  font-size: 21px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #62636A;
  margin-bottom: 32px;

  ${media.lessThan('medium')`  
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 24px;
  `}
`;

const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF;
  padding: 16px;
  background: #FF6B00;
  border-radius: 4px;  
`;

const Ornaments = styled.div`
  position: relative;
  justify-self: end;
  height: 100%;
  width: 34.65vw;
  max-width: 499px;
  background: #305BF2;
  ${media.lessThan('medium')`
    width: 100%;        
  `}
`;

const DashboardBi = styled.img`
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 2vw;
  width: 42.89vw;
  max-width: 620px;
  ${media.lessThan('medium')`
    display: none;         
  `}
`;

const DashboardBiMobile = styled.img`
  display: none;
  position: relative;
  width: 100%;
  ${media.lessThan('medium')`
    display: block;         
  `}
`;

export { 
  Section, 
  Content,
  ProductName,
  Title,
  Description,
  ActionButton,  
  Ornaments,
  DashboardBi,
  DashboardBiMobile,
};