import React from 'react';
import { Container } from "./styled"

const Footer = () => (
  <Container>
    <div>
      <img src={'/img/logo-branca.svg'} alt="Eyecare" className="svg" />
      <div className="social-icons">
        <a href="https://www.linkedin.com/company/eyecare-health/" title="linkedin" alt="linkedin" target="_blank" rel="noreferrer">
          <img src={'/img/icon-linkedin.svg'}/>
        </a>
        <a href="https://www.instagram.com/eyecare.health/" title="instagram" alt="instagram" target="_blank" rel="noreferrer">
          <img src={'/img/icon-instagram.svg'}/>
        </a>
      </div>
      <p>contato@eyecarehealth.com.br</p>
      <p>+55 (11) 93453 5994</p>
    </div>
    <div>
      <p>© 2021 Eyecare Health LTDA.  35.800.459/0001-54</p>
      <a href="https://www.eyecarehealth.com.br/termos-de-uso/">Termos de Uso</a>
      <a href="https://www.eyecarehealth.com.br/politicas-de-privacidade/">Políticas de Privacidade</a>
    </div>
  </Container>
)


export default Footer