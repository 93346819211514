import styled from 'styled-components';
import media from 'styled-media-query';

const Section = styled.section`
  padding: 0 1.67vw;
  margin-top: 104px;

  ${media.lessThan('medium')`  
    margin-top: 16px;
    padding: 0 8px;
  `}
`;

const HeaderContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 80px;
  width: 100%;
  padding: 0 32px;
  background: #305BF2;
  border-radius: 32px;

  ${media.lessThan('medium')`  
    padding: 0 24px;
    gap: 48px;
  `}

  div {
    max-width: 603px;
  }

  h1 {
    font-weight: 500;
    font-size: 40px;
    text-align: center;
    line-height: 130%;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    margin-top: 104px;
    ${media.lessThan('medium')` 
      font-weight: 600;
      font-size: 32px;
      margin-top: 40px;
    `}
  }

  p {
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #FFFFFF;
    margin-top: 14px;
    ${media.lessThan('medium')`  
      margin-top: 16px;
      font-size: 16px;
      line-height: 160%;
    `}
  }

  img {
    max-width: 1000px;
    width: 100%;
  }
`;

const AccessFeatures = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 80px;
  grid-column-gap: 84px;
  margin: 104px 16.53vw;

  ${media.lessThan('medium')`  
    grid: none;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 64px 32px;
  `}

  .feature-box{
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.04em;
      color: #1A1B23;
    }

    p {
      font-size: 16px;
      line-height: 160%;
      color: #62636A;
    }
  }
`;

const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF;
  padding: 16px;
  background: #FF6B00;
  border-radius: 4px;
  grid-column: 1 / span 2;
  justify-self: center;
  width: fit-content;

  ${media.lessThan('medium')`  
    margin: 32px auto 0 auto;
  `}
`;

export {
  Section,
  HeaderContent,
  AccessFeatures,
  ActionButton
}