import styled from 'styled-components';
import media from 'styled-media-query';

const Container = styled.header`
  position: fixed;
  padding: 0 3.33vw;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  width: 100%;
  height: 80px;
  z-index: 10;
  top: 0;
  background: #FFFFFF;
  filter: drop-shadow(0px 9.72px 19.44px rgba(0, 0, 0, 0.1));

  ${media.lessThan('medium')`  
    padding: 0 16px;
  `}

  a {
    position: relative;
    top: 6px;
  }
`;

const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF;
  padding: 16px;
  background: #FF6B00;
  border-radius: 4px;  

  ${media.lessThan('medium')`  
    font-size: 14px;
  `}
`;

export {
  Container,
  ActionButton
}