import styled from 'styled-components';
import media from 'styled-media-query';

const Section = styled.section`
  padding: 0 1.67vw;
  margin-top: 104px;
  position:relative;
  ${media.lessThan('medium')`  
    margin-top: 16px;
    padding: 0 8px;
  `}

  .glauco-anchor{ 
    position:absolute; 
    top: -148px;
    ${media.lessThan('medium')`  
      top: -96px;
    `}
  }
`;

const HeaderContent = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
  background: #F5F5F6;
  border-radius: 32px;
  ${media.lessThan('medium')`  
    gap: 16px;
  `}

  svg {
    margin-top: 96px;

    ${media.lessThan('medium')`  
      margin-top: 40px;
    `}
  }

  h1 {
    max-width: 768px;
    font-weight: 500;
    font-size: 40px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #1A1B23;
    ${media.lessThan('medium')`  
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #1A1B23;
      padding: 0 24px;
      margin-top: 8px;
    `}
  }

  p {
    max-width: 603px;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #62636A;
    ${media.lessThan('medium')`  
      font-size: 16px;
      line-height: 160%;
      text-align: center;
      color: #62636A;
      padding: 0 24px;
    `}
  }

  .image-box {
    display: flex;
    justify-content: center;
    margin-top: 72px;
    background: #FF6B00;
    border-radius: 32px;
    width: 100%;
    ${media.lessThan('medium')`  
      margin-top: 32px;
    `}

    img {
      width: 100%;
      max-width: 1150px;
      margin-top: 104px;

      &:nth-child(1) {
        ${media.lessThan('medium')`  
          display: none;
        `}
      }

      &:nth-child(2) {
        display: none;
        ${media.lessThan('medium')`  
          display: block;
          margin-top: 40px;
          max-width: 254px;
        `}
      }
    }
  }
`;

const FeaturesList = styled.div`
  margin: 80px 14.51vw 60px 14.51vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 64px;
  ${media.lessThan('medium')`  
    grid: none;
    display: flex;
    flex-direction: column;
    margin: 64px 32px;
  `}

  .feature-box {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.04em;
      color: #1A1B23;
    }

    p {
      font-size: 16px;
      line-height: 160%;
      color: #62636A;
    }

    span {
      font-size: 12px;
      line-height: 160%;
      color: #62636A;
      margin-top: 8px;
    }
  }
`

const ActionButton = styled.button`
  display: block;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #FFFFFF;
  padding: 16px;
  background: #FF6B00;
  border-radius: 4px;
  grid-column: 1 / span 2;
  width: fit-content;
  margin: 0 auto 16px auto;

  ${media.lessThan('medium')`  
    margin-bottom: 16px;
  `}
`;

export {
  Section,
  HeaderContent,
  FeaturesList,
  ActionButton
}